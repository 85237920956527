import React from "react";
import styles from "./home.module.css";
import { Link } from "react-router-dom";
import { lastUpdateDate } from "../../utils/lastUpdateDate";
import { useState } from "react";
import { Typography } from "@mui/material";
// import {Card, CardHeader, CardBody, CardFooter, Divider, Link, Image} from "@nextui-org/react"
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import SavingsRoundedIcon from "@mui/icons-material/SavingsRounded";
import { Helmet } from "react-helmet";
import { getPosts } from "../../utils/lastUpdateDate";
function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 614);
  const [getPosts1, setGetPosts] = useState(getPosts() || []);

  return (
    <>
      <Helmet>
        ‍<title>Wanij.com</title>‍
        <meta
          name="description"
          content="Explore India’s top interest rates."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@user" />
        <meta name="twitter:creator" content="@user" />
        <meta name="twitter:title" content="Pets - Products" />
        <meta name="twitter:description" content="Best FD rates" />
        <meta name="twitter:image" content="url_to_image" />
        <meta property="og:title" content="Pets - Products" />
        <meta property="og:description" content="Best Savings interest rates" />
        <meta property="og:image" content="www.wanij.com" />
        <meta property="og:url" content="www.wanij.com" />
        <meta property="og:Wanij" content="www.wanij.com" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>

      <div className={styles.homeContainer}>
        <div className={styles.homeContentDiv}>
          <h1 className={styles.homeContent}>
            {isMobile
              ? "Explore India’s top interest rates."
              : "Explore India’s top interest rates."}
          </h1>
        </div>
        <div className={styles.products}>
          <div className={styles.productCard}>
            <Link to="/FDpage">
              <div className={styles.homecard}>
                <div className={styles.hometitle}>
                  <AccountBalanceRoundedIcon
                    sx={{ width: "45px", height: "45px" }}
                  />
                  <p className={styles.title_text}>Fixed Deposits</p>
                </div>
                {/* <div className={styles.data}>
                <div className={styles.fill}>
                  Compare rates from <br />
                  over 55 banks
                </div>
              </div> */}
              </div>
            </Link>
            <Link to="/savingsPage">
              <div className={styles.homecard}>
                <div className={styles.hometitle}>
                  <SavingsRoundedIcon sx={{ width: "45px", height: "45px" }} />
                  <p className={styles.title_text}>Savings Deposits</p>
                </div>
                {/* <div className={styles.data}>
                <div className={styles.fill}>
                  Compare rates from <br />
                  over 55 banks
                </div>
              </div> */}
              </div>
            </Link>
          </div>
          <div className={styles.homeSubContent}>
            <Typography className={styles.subContent}>
              Our exhaustive comparison covers all major Indian banks, NBFCs and
              post-office schemes.
            </Typography>
            {/* <Typography sx={{ color: "#efff", textAlign: "center" }}>
              Rates last updated: {lastUpdateDate()}
            </Typography> */}
            <div
              class="flex items-center p-4 mb-4 text-md text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
              role="alert"
            >
              <svg
                class="flex-shrink-0 inline w-4 h-4 me-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span class="sr-only">Info</span>
              <div>
                <span class="font-medium">
                  This site is currently under development.
                </span>{" "}
                Data has not been updated recently.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
